
.question-type-active {
    background-color: #e7f6ef;
    color: #1cb064;
}
.paper-manage {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 100%;
    color: #333;
    font-size: 16px;

    .create-paper-box {
        display: flex;
        justify-content: space-between;
        .text {
            margin-right: 10px;
        }
    }
    .theory-box {
        margin-bottom: 20px;
    }
    .question-type-tabs {
        display: flex;
        margin-bottom: 20px;
        ::v-deep .el-tabs__item {
            width: 170px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 15px;
        }
        ::v-deep .is-active {
            transition: all 0.3s;
            background-color: #e7f6ef;
        }
        .item {
            width: 150px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            transition: all 0.3s;
            cursor: pointer;
        }
        .item:hover {
            background-color: #e7f6ef;
            color: #1cb064;
        }
    }
    .scroll-content {
        flex: 1;
        overflow: hidden;
    }
    .paging-box {
        text-align: center;
        padding: 20px 0;
    }
}
.dialog-create-paper-box {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    ::v-deep .el-button {
        width: 120px;
        height: 40px;
        padding: 0;
    }
    ::v-deep .el-button:nth-child(1) {
        margin-right: 30px;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
